<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import Layout from "../../../layouts/main.vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../../service/MethodService";
import DataForm from "./dataSession";
import { generalKhachHang } from "../../../stores/generalKhachHang";
import DialogDetailService from "../../../components/DialogDetailService.vue";
// import DashboardCard from "../../../components/DashboardCardComponent.vue";

import SearchInfinite from "../../../components/SearchInfinite.vue";
import mushroom from "cem-probe-api";
import Loading from "../../../components/loading-hello.vue";
import { datePicker } from "../../../stores/datePicker";
import { abortAPI } from "@/stores/abortAPI";

const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  getDataSession(storeDatePicker.datePicker);
};

const generalKH = generalKhachHang();
const loadingData = ref(false);
const router = useRouter();
const route = useRoute();
const checkThreshold = ref([1, 2, 3, 4]);
const checkService = ref("");
const form = reactive({ text: "" });
const valueCustomer = ref("");
const showTableCustomer = ref(true);
// const twoFisrtCardId = ref([]);
const twoFisrtCardId = ref([]);
const titleCardSession = ref("");
const isShowDialog = ref(false);
const storeDatePicker = datePicker();
const dataSearch = ref([]);
const isSearch = ref(false);
const textSearch = reactive({ text: "" });
const loadingState = ref(""); // hiển thị loading khi gọi api tìm kiếm
const inputSearch = ref(null);
const abortController = new AbortController();
const storeAbortAPI = abortAPI();

const showDialog = async (row, index) => {
  await getIdService();
  let queryURL = {
    serviceID: checkService.value,
    customer: row[index],
    start_date: MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[0]
    ),
    stop_date: MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[1]
    ),
  };

  generalKH.tableSessionData.columns.forEach(() => {
    row.forEach((itemRow, indexRow) => {
      queryURL[generalKH.tableSessionData.columns[indexRow].field_name] =
        itemRow;
    });
  });
  router
    .replace({
      name: "SessionDichVu",
      query: queryURL,
    })
    .catch(() => {});
};
const closeDialog = () => {
  isShowDialog.value = false;
};
const getIdService = async () => {
  const dataFilter = {
    filters: `dashboard=session&service_id=${checkService.value}`,
    limit: 9999,
  };
  const nameService = generalKH.listNameService.find(
    (serviceName) => serviceName.id === checkService.value
  );
  titleCardSession.value = nameService.name;

  const cardApiRes = await mushroom.card.listAsync(dataFilter);
  if (cardApiRes.result) {
    if (cardApiRes.result.length == 0) {
      createCardTypeSession(0, checkService.value);
      createCardTypeSession(1, checkService.value);
    } else if (cardApiRes.result.length == 1) {
      createCardTypeSession(cardApiRes.result.length, checkService.value);
    } else {
      twoFisrtCardId.value = [cardApiRes.result[0].id, cardApiRes.result[1].id];
    }
    isShowDialog.value = true;
  }
};
const createCardTypeSession = async (idx, serviceId) => {
  const data = {
    dashboard: "session",
    title: "",
    service_id: serviceId,
    index: idx,
  };
  const cardApiRes = await mushroom.card.createAsync(data);
  twoFisrtCardId.value.push(cardApiRes.result);
};

const changethresholdName = () => {
  getDataSession(storeDatePicker.datePicker);
};

const changeService = () => {
  if (checkService.value.length > 0) {
    getDataSession(storeDatePicker.datePicker);
  }
};

const downloadInfoCardItem = async (cardTitle) => {
  const dataFilter = {
    service_ids: [checkService.value],
    probe_id: localStorage.pid,
    quality_levels: checkThreshold.value,
    search_key: form.text,
    is_download: true,
    limit: tableRules.limit,
    offset: tableRules.offset,
  };

  if (storeDatePicker.datePicker) {
    dataFilter.start_date = storeDatePicker.datePicker[0];
    dataFilter.stop_date = storeDatePicker.datePicker[1];
  }

  await generalKH.getFileSessionData(dataFilter);

  if (generalKH.fileSessionData.length > 0) {
    var mediaType =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var userInp = generalKH.fileSessionData;
    var a = document.createElement("a");
    a.href = mediaType + userInp;
    a.download = cardTitle;
    a.click();
    a.remove();
  }
};

const getDataSession = async (date) => {
  console.log('date', date)
  const dataFilter = {
    service_ids: [checkService.value],
    probe_id: localStorage.pid,
    quality_levels: checkThreshold.value,
    // search_key: textSearch.text, // tìm kiếm bên session em sẽ làm giống UX của bên Dashboard
    search_key: form.text,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort_col_id: tableRules.sort,
    sort_type: tableRules.sort_type,
  };

  const search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    date[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    date[1]
  );

  router
    .replace({
      name: "SessionDichVu",
      query: queryForPush,
    })
    .catch(() => {});

  if (date) {
    dataFilter.start_date = date[0];
    dataFilter.stop_date = date[1];
  }

  loadingData.value = true;
  if (generalKH.listNameService.length > 0) {
    await generalKH.getSessionData(dataFilter);
  }

  if (generalKH.tableSessionData) {
    loadingData.value = false;
  }

  if (generalKH.tableSessionData.data.length > 0) {
    showTableCustomer.value = true;
  } else {
    showTableCustomer.value = false;
  }
};

const checkFixed = (index, colFixed) => {
  if (index <= colFixed) return true;
};

const checkDataDatetime = (x, data) => {
  if (x.data_type === "DateTime") {
    return MethodService.formatDate(data);
  }
  return data;
};

const setChangeData = async () => {
  const urlParams = window.location.search.slice(1);
  const searchParams = new URLSearchParams(urlParams);

  if (searchParams.get("service_id")) {
    checkService.value = searchParams.get("service_id");
  } else {
    checkService.value = await generalKH.listNameService.map(
      (item) => item.id
    )[0];
  }

  if (searchParams.get("customer")) {
    setChangeData;
    valueCustomer.value = searchParams.get("customer");
    textSearch.text = searchParams.get("customer");
  } else {
    valueCustomer.value = generalKH.listProbe[0]?.key;
    textSearch.text = generalKH.listProbe[0]?.key;
    form.text = generalKH.listProbe[0]?.key;
  }

  if (searchParams.getAll("quality_levels").length > 0) {
    checkThreshold.value = searchParams
      .getAll("quality_levels")
      .map((x) => Number(x));
  } else {
    checkThreshold.value = [1, 2, 3, 4];
  }

  const startDate = searchParams.get("start_date");
  const stopDate = searchParams.get("stop_date");
  if (startDate?.length > 0 && stopDate?.length > 0) {
    localStorage.setItem("dateSelect", [startDate, stopDate]);
    storeDatePicker.setDatePicker([startDate, stopDate]);
  }

  const macID = searchParams.get("mac");
  if (macID?.length > 0) {
    textSearch.text = macID;
    form.text = macID;
  }
};

const checkChangeKeySearch = (keySearch) => {
  isSearch.value = false;
  if (window.waitSearchSession) clearTimeout(window.waitSearchSession);
  window.waitSearchSession = setTimeout(() => {
    getSuggestSearch(keySearch);
  }, 700);
};
const getSuggestSearch = async (keySearch) => {
  if (!keySearch) return;
  loadingState.value = "loading";
  isSearch.value = true;

  const body = {
    service_ids: [checkService.value],
    probe_id: localStorage.pid,
    quality_levels: checkThreshold.value,
    search_key: keySearch,
    limit: 20,
    offset: 0,
    start_date: storeDatePicker.datePicker[0],
    stop_date: storeDatePicker.datePicker[1],
  };
  try {
    const response = await mushroom.probe_data.getSumDataCustomerAsync({
      body: body,
      settings: {
        abortController
      }
    });
    dataSearch.value = [];

    if (response.result.data.length > 0) {
      const keySearchs = keySearch.split(" -- ");
      response.result.data.forEach((row) => {
        row.some((cell, index) => {
          if (response.result.columns[index].search_enable !== true)
            return false;

          return keySearchs.some((key) => {
            if (cell.toString().toLowerCase().includes(key)) {
              const value = row
                .filter((c, i) => response.result.columns[i].search_enable)
                .join(" -- ");
              dataSearch.value.push({
                key: value,
                label: value,
              });
              return true;
            }
            return false;
          });
        });
      });
      dataSearch.value = new Set(dataSearch.value);
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loadingState.value = "";
  }
};
const keySearch = (data) => {
  inputSearch.value.focus();
  textSearch.text = data;
  isSearch.value = false;
  onSubmit();
};
const onSubmit = () => {
  const urlParams = window.location.search.slice(1);
  const searchParams = new URLSearchParams(urlParams);

  if (searchParams.get("customer")) {
    valueCustomer.value = searchParams.get("customer");
  } else valueCustomer.value = "";

  if (textSearch.text.includes(" --")) {
    form.text = textSearch.text.substr(0, textSearch.text.indexOf(" --"));
  } else form.text = textSearch.text;
  getDataSession(storeDatePicker.datePicker);
  isSearch.value = false;
};
const clearValueSearch = () => {
  isSearch.value = false;
  dataSearch.value = [];
};

const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  getDataSession(storeDatePicker.datePicker);
};

watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    loadingData.value = true;
    await getDataSession(storeDatePicker.datePicker);
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  }
  await generalKH.getSummary();
  await generalKH.getProbes(localStorage.pid);
  await setChangeData();
  await generalKH.getThresholdName();
  await getDataSession(storeDatePicker.datePicker);
});
</script>

<template>
  <Layout>
    <div class="card">
      <loading v-if="loadingData"></loading>
      <div class="card-header card-header-table">
        <h3 class="text-uppercase mb-0">Bảng thống kê session theo dịch vụ</h3>
        <div
          v-show="
            checkService?.length > 0 &&
            generalKH.tableSessionData.data?.length > 0
          "
          class="action action-download button-update"
          :title="`${$t('t-download')}`"
          @click="downloadInfoCardItem('bảng session')"
        >
          <i class="ri-download-2-line icon-download"></i>
        </div>
      </div>

      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search">
            <el-form
              :model="textSearch"
              label-width="40px"
              style="display: flex; width: 401px"
              @submit.prevent
            >
              <el-input
                v-model="textSearch.text"
                :placeholder="`${$t('t-search')}`"
                clearable
                @input="checkChangeKeySearch(textSearch.text)"
                @clear="clearValueSearch"
                ref="inputSearch"
              />
              <el-button
                type="primary"
                native-type="submit"
                @click="onSubmit(checkService)"
                style="margin-left: 20px"
                >{{ $t("t-search") }}</el-button
              >
            </el-form>
            <search-infinite
              :loadingState="loadingState"
              :dataSearch="dataSearch"
              :isSearch="isSearch"
              @keySearch="keySearch"
            ></search-infinite>
          </div>

          <div class="main-body-checkbox">
            <div class="checkbox-content" style="margin-right: 10px">
              <el-checkbox-group
                :key="item.level"
                v-for="item in generalKH.thresholdName"
                v-model="checkThreshold"
                @change="changethresholdName"
              >
                <el-checkbox :label="item.level" name="type">
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="checkbox-content">
              <el-radio-group
                :key="item.id"
                v-for="item in generalKH.listNameService"
                v-model="checkService"
                @change="changeService"
              >
                <el-radio :label="item.id" name="type">
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>

          <div
            v-show="
              checkService?.length > 0 &&
              generalKH.tableSessionData.data?.length == 0
            "
            class="general-alert"
          >
            <i class="mdi mdi-database-alert-outline mx-2"></i>
            {{ $t("t-not-data") }}
          </div>

          <div
            v-show="
              checkService?.length > 0 &&
              generalKH.tableSessionData.data?.length > 0
            "
          >
            <el-table
              :data="generalKH.tableSessionData.data"
              border
              stripe
              style="width: 100%"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column label="STT" width="60" align="center" fixed>
                <template #default="scope">
                  <div>
                    {{ scope.$index + tableRules.offset + 1 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(col, index) in generalKH.tableSessionData.columns"
                :prop="col.col_id"
                :key="col.id"
                :label="col.title"
                :min-width="180"
                align="center"
                :fixed="checkFixed(index, generalKH.tableSessionData.colFixed)"
                :sortable="col.sortable ? true : false"
              >
                <template #default="scope">
                  <div
                    v-if="col.service_id"
                    @click="
                      showDialog(
                        scope.row,
                        generalKH.tableSessionData.columns.findIndex(
                          (col) => col.is_identity === true
                        )
                      )
                    "
                    class="content-detail"
                  >
                    {{
                      checkDataDatetime(
                        col,
                        generalKH.tableSessionData.data[scope.$index][index]
                      )
                    }}
                  </div>
                  <div v-if="!col.service_id">
                    {{
                      checkDataDatetime(
                        col,
                        generalKH.tableSessionData.data[scope.$index][index]
                      )
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="mt-3" style="float: right">
              <el-pagination
                small
                v-model:currentPage="tableRules.page"
                v-model:page-size="tableRules.limit"
                :page-sizes="tableRules.lengthMenu"
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="generalKH.tableSessionData.total"
                @size-change="fn_tableSizeChange"
                @current-change="fn_tableCurentChange"
                @prev-click="fn_tablePrevClick"
                @next-click="fn_tableNextClick"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <DialogDetailService
      :cardId="twoFisrtCardId"
      :cardTitle="titleCardSession"
      :isShowDialog="isShowDialog"
      @closeDialog="closeDialog"
    />
  </Layout>
</template>

<style scoped>
.main-body {
  margin: 10px 20px;
}
.main-body-search {
  margin-bottom: 10px;
  width: 150px;
}
.main-body-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.checkbox-content {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 0 5px;
  margin-bottom: 10px;
}
.el-checkbox {
  margin-bottom: 0;
  margin-right: 25px;
}
.content-detail {
  cursor: pointer;
}
.button-update {
  cursor: pointer;
}
.icon-download {
  font-size: 20px;
  /* margin-right: 10px; */
}
.el-radio-group {
  margin-right: 25px;
}
.el-radio {
  margin-bottom: 0;
}
.el-table {
  height: calc(100vh - 295px);
}
.card-header-table {
  background-color: #fff;
  padding: 18px;
}
:deep.el-table th.el-table__cell {
  background-color: #f3f6f9 !important;
}
:deep .el-table.is-scrolling-left th.el-table-fixed-column--left {
  background-color: #f3f6f9;
}
</style>
