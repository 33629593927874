<script setup>
import mushroom from "cem-probe-api";
import DashboardCard from "./DashboardCardComponent.vue";
import { ref, defineProps, defineEmits, watch } from "vue";
import { changeEdit } from "../stores/changeEdit";

const storeChangeEdit = changeEdit();
const dialogVisible = ref(false);
const sessionDashboard1 = ref(0);
const sessionDashboard2 = ref(0);
const isLoadingDialog = ref(true);

const props = defineProps({
  cardId: Array,
  cardTitle: String,
  isShowDialog: Boolean,
});
const headerOptionsCardItem1 = ref({});
const headerOptionsCardItem2 = ref({});

const emit = defineEmits([""]);

const configDashboard = () => {
  storeChangeEdit.changeSettingEdit();
  sessionDashboard1.value += 1;
  sessionDashboard2.value += 1;
};

const closeDialog = () => {
  emit("closeDialog");
  if (storeChangeEdit.showEdit) storeChangeEdit.changeSettingEdit();
  isLoadingDialog.value = true;
};
const getInfoCard = async (idCard) => {
  try {
    const response = await mushroom.card.findByIdAsync({
      id: idCard,
    });
    if (response.result.header) {
      return response.result.header;
    } else {
      const arr = {
        is_hidden: false,
        color: "#a4a4a4",
        backgroundColor: "#f4f4f4",
      };
      return arr;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

watch(
  () => props.isShowDialog,
  (newData) => {
    dialogVisible.value = newData;
  }
);

watch(
  () => props.cardId,
  (newData) => {
    // sử dụng setTimeout để chart không hiển thị lỗi giao diện
    setTimeout(async () => {
      sessionDashboard1.value += 1;
      sessionDashboard2.value += 1;
      isLoadingDialog.value = false;
      headerOptionsCardItem1.value = await getInfoCard(props.cardId[0]);
      headerOptionsCardItem2.value = await getInfoCard(props.cardId[1]);
    }, 1000);

    console.log("cardid", newData);
  }
);
</script>

<template>
  <div class="session-dashboard">
    <el-dialog v-model="dialogVisible" title="" width="90%" align-center :close-on-click-modal="false"
      :show-close="false" class="dialog-session">
      <template #header="{ titleId }">
        <div class="align-items-center d-flex justify-content-between my-header">
          <p :id="titleId" class="title-session">
            Session detail {{ props.cardTitle }}
          </p>
          <div class="d-flex">
            <button class="btn btn-icon btn-ghost-secondary rounded-circle btn-edit" :title="`${$t('t-download')}`">
              <i class="icon ri-settings-4-line fs-22" :title="$t('t-config')" @click="configDashboard"></i>
            </button>
            <button class="btn btn-icon btn-ghost-dark rounded-circle btn-edit" :title="`${$t('t-download')}`">
              <i class="icon ri-close-circle-line fs-22" :title="$t('t-close')" @click="closeDialog"></i>
            </button>
          </div>
        </div>
      </template>
      <div>
        <div v-if="isLoadingDialog" class="loading-dialog">
          <div class="text-center">
            <i style="font-size: 40px; opacity: 0.5; color: #0f6a33" class="bx bx-loader-alt loading-icon"></i>
            <p>{{ $t("t-loading-get-data") }}</p>
          </div>
        </div>
        <b-row v-if="!isLoadingDialog">
          <b-col md="6">
            <dashboard-card :cardId="props.cardId[0]" :cardTitle="`TitleCard`" :headerOptions="headerOptionsCardItem1"
              @reRenderCardAfterOtionCard="reRenderCardAfterOtionCard" :key="sessionDashboard1"></dashboard-card>
          </b-col>

          <b-col md="6">
            <dashboard-card :cardId="props.cardId[1]" :cardTitle="`TitleCard`" :headerOptions="headerOptionsCardItem2"
              @reRenderCardAfterOtionCard="reRenderCardAfterOtionCard" :key="sessionDashboard2"></dashboard-card>
          </b-col>
        </b-row>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss">
.title-session {
  font-size: 24px;
  color: #a4a4a4;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
}

.session-dashboard {
  .el-dialog__body {
    height: calc(100vh - 130px);
    padding: 0 15px 15px 15px;
    overflow: hidden;

    .el-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .el-dialog__body {
        height: 100%;
        padding: 20px 20px 10px;
      }
    }
  }

  i.icon,
  .action {
    cursor: pointer;
  }

  .button-update {
    margin-right: 10px;
  }

  .dashboard-card {
    background-color: #f4f4f4;
  }

  .card-header {
    border: none;
  }

  .action-sort,
  .btn-close-cards,
  .action-mini,
  .title-card {
    display: none;
  }

  .card-header-55 {
    height: 35px;
  }

  .my-header {
    padding: 15px 0 15px 15px;
  }

  .grid-stack.grid-stack-animate {
    height: calc(100vh - 180px) !important;
    overflow-x: auto;
  }
}

.el-dialog__header {
  margin-right: 0px;
  padding: 5px 15px 0;
  background-color: #fff;

  .my-header {
    text-align: right;
  }
}

.loading-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

:deep .grid-stack .grid-stack-item {
  box-shadow: 0 0.46875rem 2.1875rem #04091408, 0 0.9375rem 1.40625rem #04091408,
    0 0.25rem 0.53125rem #0409140d, 0 0.125rem 0.1875rem #04091408;
}

.dialog-session>.el-dialog__body,
.dialog-session>.el-dialog__header {
  background-color: #f4f4f4;
}
</style>
